import React from "react";
import {FieldValues, useFormContext} from "react-hook-form";
import {
    StyledCheckboxOrRadioInput,
    StyledInput,
    StyledSelect,
    StyledTextArea
} from "../../styledcomponents/FormStyledComponents";

interface InputProps extends React.HTMLProps<HTMLInputElement>{
    name?: string;
    required?: boolean;
    errorMessage?: string;
    matchesOtherField ?: string;
}

/** Returns the displayed contents of a corresponding label. */
function getLabelValue(name:string) {
    return document.querySelector(`label[for='${name}']`)?.innerHTML?.replace(/(\(Required\))|(\(Optional\))/, "")
}

function Input(props : Readonly<InputProps>) {
    const { register} = useFormContext(); // retrieve all hook methods

    const labelValue = getLabelValue(props.name!) ?? 'Field'

    //if we need to match another field (e.g. confirm password), add validation for that
    const matchesOtherFieldValidation = props.matchesOtherField ? {
        value: (val : any, formValues: FieldValues) => 
            formValues[props.matchesOtherField!] != val ?
                `${labelValue} must match ${getLabelValue(props.matchesOtherField!)}`
                : undefined
        } : undefined;

    let field;
    if (props.name) {
        field = {
            ...register(props.name, {
                required: {
                    value: props?.required! ?? false,
                    message: `${labelValue} is required.`,
                },
                pattern: {
                    value: RegExp(props.pattern ?? ''),
                    message: props?.errorMessage ?? `${labelValue} is invalid.`
                },
                validate: matchesOtherFieldValidation
            })
        };
    }

    if (props.type === 'checkbox' || props.type === 'radio') {
        return (
            <StyledCheckboxOrRadioInput
                {...field}
                type={props.type}
                disabled={props.disabled}
                value={props.value}
                key={props.key}
                onChange={props.onChange ?? field?.onChange}
                defaultChecked={props.defaultChecked}
                aria-label={props["aria-label"]}
                id={props.id}
            />
        );
    }

    return <StyledInput id={props.id ?? props.name} {...field} type={props.type} value={props.value} onChange={props.onChange ?? field?.onChange} disabled={props.disabled} aria-label={props["aria-label"]} />
}

function CheckboxInput(props : InputProps) {
    return <Input type={'checkbox'} {...props}/>
}

function RadioInput(props : InputProps) {
    return <Input type={'radio'} {...props}/>
}

function Select(props : React.HTMLProps<HTMLSelectElement>) {
    const { register } = useFormContext(); // retrieve all hook methods

    if (props.name === undefined) {
        throw new Error('Name must be provided to a select')
    }

    return (
        <StyledSelect {...register(props.name)} id={props.id ?? props.name} aria-label={props["aria-label"]}>
            {props.children}
        </StyledSelect>
    )
}

function TextArea(props : React.HTMLProps<HTMLTextAreaElement>) {
    const { register } = useFormContext(); // retrieve all hook methods

    if (props.name === undefined) {
        throw new Error('Name must be provided to a textarea')
    }

    return (
        <StyledTextArea id={props.id ?? props.name} {...register(props.name)} />
    )
}

export {Input, CheckboxInput, RadioInput, Select, TextArea}