import React, { useRef, Suspense } from 'react';
import {Link, Navigate, Route, Routes, RedirectFunction, useLocation} from "react-router-dom";
import styled from "styled-components";
import './App.css';
import { useAuth } from "./auth/AuthProvider";
import MessageContainer from "./components/MessageContainer";
import SideNav from "./components/SideNav";
import firstEnergyLogo from './FE_Logo_White.svg'
import { useMessageService } from "./hooks/useMessageService";
import {FlexRowContainer} from "./styledcomponents/MiscStyledComponents";
import {Page, usePath} from "./PathProvider";
import {useIdleTimer} from "react-idle-timer";
import ForcedResetPassword from "./components/ForcedResetPassword";


const AdminLogin                = React.lazy(() => import("./components/AdminLogin"));
const Users                     = React.lazy(() => import("./components/admin/Users"));
const ForgotCredentials         = React.lazy(() => import("./components/ForgotCredentials"));
const ResetForgotPassword       = React.lazy(() => import("./components/ResetForgotPassword"));
const Login                     = React.lazy(() => import("./components/Login"));
const Register                  = React.lazy(() => import("./components/Register"));
const ReviewRegister            = React.lazy(() => import("./components/ReviewRegister"));
const UserBuildings             = React.lazy(() => import("./components/admin/UserBuildingsPage"));
const AdminViewBuildings        = React.lazy(() => import("./components/admin/AdminViewBuildings"));
const NonAdminViewBuildings     = React.lazy(() => import("./components/user/NonAdminViewBuildings"));
const AddBuildingAssoc          = React.lazy(() => import("./components/user/AddBuildingAssoc"));
const ReviewAddBuildingAssoc    = React.lazy(() => import("./components/user/ReviewAddBuildingAssoc"));
const BuildingUsers             = React.lazy(() => import("./components/admin/BuildingUsersPage"));
const DeleteBuilding            = React.lazy(() => import("./components/DeleteBuilding"));
const AdminAddDeleteBuilding    = React.lazy(() => import("./components/admin/AdminAddDeleteBuilding"));
const ResetPassword             = React.lazy(() => import("./components/user/ResetPassword"));
const AuditLogTablePage         = React.lazy(() => import("./components/AuditLogTablePage"));
const NoPageFound               = React.lazy(() => import("./components/NoPageFound"));
const AcknowledgementPage       = React.lazy(() => import("./components/AcknowledgementPage"));
const ManageTenants             = React.lazy(() => import("./components/ManageTenants"));
const EditBuilding              = React.lazy(() => import("./components/EditBuilding"));
const ReviewEditBuilding        = React.lazy(() => import("./components/ReviewEditBuilding"));
const EditUser                  = React.lazy(() => import("./components/admin/EditUser"));
const ReviewEditUser            = React.lazy(() => import("./components/admin/ReviewEditUser"));
const SearchTenantPage          = React.lazy(() => import("./components/SearchTenantPage"));
const SearchTenantResults       = React.lazy(() => import("./components/SearchTenantResults"));
const SearchTenantResultsReview = React.lazy(() => import("./components/SearchTenantResultsReview"));
const ManageAccount             = React.lazy(() => import("./components/user/ManageAccount"));
const UsageRequestPage          = React.lazy(() => import("./components/user/UsageRequestPage"));
const CancelUsageRequest        = React.lazy(() => import("./components/user/CancelUsageRequest"));
const DeleteTenant              = React.lazy(() => import("./components/admin/DeleteTenant"));

const MainContentWrapper = styled.div<{isLoggedIn : boolean}>`
    grid-area: wrapper;
    display: grid;
    /* If user is logged in we want to show a side nav */
    grid-template-areas:
      ${props => props.isLoggedIn ? `"nav main main main main"` : `"main"` };
    margin:1.5em 5rem;
    @media screen and (max-width: 1024px) {
        margin: 40px 1rem;
    }
    grid-template-columns: repeat(${props => props.isLoggedIn ? 5 : 1 }, 1fr);
`

const LogoContainer = styled(FlexRowContainer)`
    margin-top: .55rem;
    gap: 0 !important;
`

const LogoImage = styled.img`
    
`
const LogoText = styled(Link)`
text-decoration: none;
color: white;
font-size: 1.5rem;
`

function App() {
    const auth = useAuth();
    const location = useLocation();
    const messageService = useMessageService();
    const path = usePath();

    const locationRef = useRef(location);

    // If the location changes:
    // 1. Clear all messages
    // 2. If there are success of warning messages to show upon redirect, show them
    React.useEffect(() => {

        document.title = `${process.env.REACT_APP_TITLE_NAME} - ${path.getTitleFromPathName(location.pathname)}`;

        // verify the path actually changed
        if (location.pathname !== locationRef.current.pathname) {
            messageService.clearAll();

            const {successMessage, warningMessage} = location.state || {};

            if (successMessage) { messageService.success(successMessage); }
            if (warningMessage) { messageService.warning(warningMessage); }

            locationRef.current = location;
        }
    }, [location]);

    // log the user out if they're idle
    const onIdle = () => {
        if (auth.user) {
            auth.logout("Your session has timed out.");
        }
    }

    useIdleTimer({
        onIdle,
        timeout: process.env.REACT_APP_IDLE_TIMEOUT_MINUTES ? parseInt(process.env.REACT_APP_IDLE_TIMEOUT_MINUTES)*60000 : 90000
    })

    return (
        <div className="App">
            <header>
                <LogoContainer>
                <Link to={path.get(Page.Root)}><img src={firstEnergyLogo} style={{ height: 30, marginTop:"0.3em", marginRight:"0.3em" }} alt="logo" /></Link>
                <div><LogoText to={path.get(Page.Root)} style={{textDecoration: 'none'}}>&nbsp;Benchmarking</LogoText></div>
                </LogoContainer>
                {
                    auth.user &&
                    <div>Welcome {auth.user.fullName}</div>
                }
            </header>
            {/*This will display any messages added to the app via the messageService*/}
            <MessageContainer />
            {
                auth.user
                    ?
                    <MainContentWrapper isLoggedIn={true}>
                        <nav><SideNav /></nav>
                        <main>
                            <Suspense fallback={<div>Loading...</div>}>
                                <Routes>
                                    {/*admin only pages*/}
                                    {
                                        auth.user.admin &&
                                        <React.Fragment>
                                            <Route path={path.get(Page.Root)} element={<Navigate to={path.get(Page.AdminViewBuildings)} replace />  } />

                                            <Route path={path.get(Page.AdminViewBuildings)}      element={<AdminViewBuildings />  } />
                                            <Route path={path.get(Page.AdminViewUsers)}          element={<Users />               } />
                                            <Route path={path.get(Page.AdminViewUserBuildings)}  element={<UserBuildings />       } />
                                            <Route path={path.get(Page.AdminViewBuildingUsers)}  element={<BuildingUsers />       } />
                                            <Route path={path.get(Page.AdminAddBuilding)}        element={<AdminAddDeleteBuilding />    } />
                                            <Route path={path.get(Page.AdminRemoveBuilding)}     element={<AdminAddDeleteBuilding isDelete/>} />
                                            <Route path={path.get(Page.AuditLog)}                element={<AuditLogTablePage />   } />
                                            <Route path={path.get(Page.EditUser)}                element={<EditUser />            } />
                                            <Route path={path.get(Page.ReviewEditUser)}          element={<ReviewEditUser />      } />
                                        </React.Fragment>
                                    }

                                    {/*user only pages*/}
                                    {
                                        !auth.user.admin &&
                                        <React.Fragment>
                                            <Route path={path.get(Page.Root)} element={<Navigate to={path.get(Page.NonAdminViewBuildings)} replace />  } />

                                            <Route path={path.get(Page.ManageAccount)}         element={<ManageAccount />         } />
                                            <Route path={path.get(Page.NonAdminViewBuildings)} element={<NonAdminViewBuildings /> } />
                                            <Route path={path.get(Page.UsageRequestPage)}      element={<UsageRequestPage />      } />
											<Route path={path.get(Page.CancelUsageRequest)}    element={<CancelUsageRequest />    } />
                                            <Route path={path.get(Page.ResetPassword)}         element={<ResetPassword />         } />
                                        </React.Fragment>
                                    }

                                    {/*Pages available to any user*/}
                                    <Route path={path.get(Page.SearchTenant)}               element={<SearchTenantPage />          } />
                                    <Route path={path.get(Page.SearchTenantResults)}        element={<SearchTenantResults />       } />
                                    <Route path={path.get(Page.SearchTenantsResultsReview)} element={<SearchTenantResultsReview /> } />
                                    <Route path={path.get(Page.ManageTenants)}              element={<ManageTenants />             } />
                                    <Route path={path.get(Page.EditBuilding)}               element={<EditBuilding />              } />
                                    <Route path={path.get(Page.ReviewEditBuilding)}         element={<ReviewEditBuilding />        } />
                                    <Route path={path.get(Page.DeleteBuilding)}             element={<DeleteBuilding />            } />
                                    <Route path={path.get(Page.AddBuildingAssoc)}           element={<AddBuildingAssoc />          } />
                                    <Route path={path.get(Page.ReviewAddBuildingAssoc)}     element={<ReviewAddBuildingAssoc />    } />
                                    <Route path={path.get(Page.DeleteTenant)}               element={<DeleteTenant />              } />
                                    <Route path={'*'}                          		        element={<NoPageFound />               } />

                                </Routes>
                            </Suspense>
                        </main>
                    </MainContentWrapper>
                    :
                    <MainContentWrapper isLoggedIn={false}>
                        <main>
                            <Suspense fallback={<div>Loading...</div>}>
                                <Routes>
                                    <Route index path={path.get(Page.AdminLogin)}     element={<AdminLogin />          } />
                                    <Route index path={path.get(Page.Login)}          element={<Login />               } />
                                    <Route path={path.get(Page.ForgotCredentials)}    element={<ForgotCredentials />   } />
                                    <Route path={path.get(Page.ResetForgotPassword)}  element={<ResetForgotPassword /> } />
                                    <Route path={path.get(Page.Register)}             element={<Register />            } />
                                    <Route path={path.get(Page.ReviewRegister)}       element={<ReviewRegister />            } />
                                    <Route path={path.get(Page.AcknowledgementPage)}  element={<AcknowledgementPage /> } />
                                    <Route path={path.get(Page.ForcedResetPassword)}  element={<ForcedResetPassword /> } />
                                    {
                                        /*
                                            If user is not logged in, and they try to navigate to any url besides login,
                                            then this will redirect them to the login page
                                        */
                                    }
                                    <Route path={'*'} element={<Navigate to="/login" />} />
                                </Routes>
                            </Suspense>
                        </main>
                    </MainContentWrapper>
            }
        </div>
    );
}

export default App;
