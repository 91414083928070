const testProdLinks = false; //temporarily set this to true to test prod links locally

export const LINK_HOST = (!testProdLinks && window.location.origin.match(/localhost|\.dev|\.qa/g)) 
    ? "https://www-qa-b.firstenergycorp.com" : "https://firstenergycorp.com"

const urlForHost = (url : string) => LINK_HOST + url;

export const NJ_USER_GUIDE_URL 
    = urlForHost('/save_energy/save_energy_new_jersey/for-your-business/benchmark-your-building.html');
export const USER_GUIDE_URL 
    = urlForHost('/save_energy/save_energy_maryland/for_your_business/benchmark-your-building.html');
export const CONTACT_US_URL 
    = urlForHost('/content/customer/save_energy/contact-us.html');
export const BLANK_CONSENT_FORM_URL_NJ 
    = urlForHost('/content/dam/customer/Saving%20Energy/Files/NJ/JCPL%20NJ%20Benchmarking%20Consent%20Form.pdf');
export const BLANK_CONSENT_FORM_URL_MD
    = urlForHost('/content/dam/customer/Saving%20Energy/Files/MD/MD-Benchmarking-Consent-Form.pdf');